import { APP_INITIALIZER, inject } from '@angular/core';
import { PricelistDto, PricelistEntityName, PricelistItemDto, PricelistItemEntityName } from '@nest/domain/entities-metadata/dtos';
import { PricelistDataAccessService, PricelistItemDataAccessService } from '@nest/domain/pricelists/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const paths = autoPathsFor<PricelistDto>();
const itemPaths = autoPathsFor<PricelistItemDto>();

export const PricelistDefaultElements = fieldsToElements(paths(['type', 'code', 'name', 'currency', 'sourcePricelist.name']));
export const PricelistItemDefaultElements = fieldsToElements(itemPaths(['item.code', 'item.name', 'price', 'validFrom', 'validTo', 'currency']));

export function providePricelistsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				// let pricelistRuleDataAccess = inject(PricelistRuleDataAccessService);
				let pricelistDataAccess = inject(PricelistDataAccessService);
				let pricelistItemDataAccess = inject(PricelistItemDataAccessService);

				return () => {
					// entityMetadata.registerEntity({
					// 	entityName: PricelistRuleEntityName,
					// 	dataAccess: pricelistRuleDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<PricelistRuleDto>()(['id']))
					// });

					entityMetadata.registerEntity({
						entityName: PricelistEntityName,
						dataAccess: pricelistDataAccess,
						defaultElements: PricelistDefaultElements
					});

					entityMetadata.registerEntity({
						entityName: PricelistItemEntityName,
						dataAccess: pricelistItemDataAccess,
						defaultElements: PricelistItemDefaultElements
					});
				};
			}
		}
	];
}
